<svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" transform="matrix(1 0 0 -1 0 20)" />
    <path d="M14 14L6 6" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6 14L14 6" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
</svg>
