import { Component } from '@angular/core';


@Component({
    selector: 'app-cross-circle-inverse',
    imports: [],
    templateUrl: './cross-circle-inverse.component.html',
    styles: []
})
export class CrossCircleInverseComponent {}
